import { mapState } from 'vuex'
import moment from 'moment'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId,
    checkbookEntries: state => state.bankaccount.checkbookEntries,
    bankAccountListings: state => state.bankaccount.bankAccountListings,
    summedChecks: state => state.bankaccount.summedUnreconciledBankAccounts,
    summedDebits: state => state.bankaccount.summedUnreconciledCheckbookDebits,
    summedCredits: state => state.bankaccount.summedUnreconciledCheckbookCredits,
    summedAccounts: state => state.bankaccount.summedUnreconciledCheckbookEntries,
    summedDebitAccounts: state => state.bankaccount.summedUnreconciledBankAccountListingDebits,
    summedCreditAccounts: state => state.bankaccount.summedUnreconciledBankAccountListingCredits,
    glAccountBalance: state => state.bankaccount.glBalance,
    bankAccountBalance: state => state.bankaccount.bankAccountUnclearedBalance
  }),

  selectedTargetString() {
    console.log('selectedTargetString', this.targetDate, this.formatDateFnPost(this.targetDate))

    return this.targetDate ? this.formatDateFnPost(this.targetDate) : ''
  },
  totalSelectedChecks() {
    return this.checkedRows1.reduce((acc, item) => acc + item.amount, 0)
  },
  totalSelectedTransactions() {
    return this.checkedRows2.reduce((acc, item) => acc + item.amount, 0)
  },

  minStartDate() {
    return moment('01-01-1980').toDate()
  },

  maxStartDate() {
    return moment(this.endDate)
      .subtract(1, 'day')
      .toDate()
  },

  minEndDate() {
    return moment(this.startDate)
      .add(1, 'day')
      .toDate()
  },

  maxEndDate() {
    return moment().toDate()
  },
  debitCount() {
    return this.checkbookEntries.reduce((acc, item) => (item.amount < 0 ? acc + 1 : acc + 0), 0)
  },
  creditCount() {
    return this.checkbookEntries.reduce((acc, item) => (item.amount > 0 ? acc + 1 : acc + 0), 0)
  },
  debitTransactionCount() {
    return this.bankAccountListings.reduce((acc, item) => (item.amount < 0 ? acc + 1 : acc + 0), 0)
  },
  creditTransactionCount() {
    return this.bankAccountListings.reduce((acc, item) => (item.amount > 0 ? acc + 1 : acc + 0), 0)
  },
  bankAccountBalanceCheck() {
    return this.bankAccountBalance == null ? 0 : this.bankAccountBalance.balance
  },
  balanceAmountCheck() {
    return this.glAccountBalance == null ? 0 : this.glAccountBalance.balanceAmount
  },
  reconcilingBalance() {
    return this.glAccountBalance == null
      ? 0
      : this.glAccountBalance.balanceAmount + this.summedDebits * -1 - this.summedCredits
  },
  differenceBalance() {
    return this.reconcilingBalance - this.bankAccountBalanceCheck
  },
  isBalanceTargetDate() {
    return this.bankAccountBalance == null
      ? false
      : this.formatDateFnPost(this.bankAccountBalance.balanceDate) == this.selectedTargetString
      ? true
      : false
  }
}
