import { PRESET_FORM_VALUES } from './data'
//
import { notifyMessage, notifyError } from '@/services/notify'
import { ValidationError } from '@/services/errors'
import kms from '@/services/kms'
//
import { mapActions } from 'vuex'
import moment from 'moment'

export const methods = {
  ...mapActions('bankaccount', [
    'loadCheckbookEntries',
    'loadBankAccountListings',
    'loadGlBalance',
    'loadBankAccountBalanceUncleared'
  ]),

  processTargetDate(option) {
    console.debug(option)
    console.debug('targetDate=' + this.targetDate)
  },

  async reloadGridsWithTargetDate() {
    this.loading = true

    this.checkedRows1 = []
    this.checkedRows2 = []

    console.debug('selectedTargetString', this.selectedTargetString)

    await this.loadCheckbookEntries({
      bankAccountID: this.$route.params.id,
      showUnreconciledOnly: true,
      targetDate: this.selectedTargetString
    })
    console.debug('checkbookEntries=' + JSON.stringify(this.checkbookEntries))

    await this.loadBankAccountListings({
      bankAccountID: this.$route.params.id,
      showUnreconciledOnly: true,
      targetDate: this.selectedTargetString
    })
    console.debug('bankAccountListings=' + JSON.stringify(this.bankAccountListings))

    let paramAccountID = 0

    try {
      paramAccountID =
        this.bankAccountListings == undefined ||
        this.bankAccountListings == null ||
        this.bankAccountListings[0].bankAccount == null
          ? 0
          : this.bankAccountListings[0].bankAccount.bankGLAccountID
    } catch (e) {
      console.debug(e)
    }

    if (paramAccountID > 0) {
      await this.loadGlBalance({
        accountID: paramAccountID,
        targetDate: this.selectedTargetString
      })
      console.debug('glBalance=' + JSON.stringify(this.glBalance))
    }

    await this.loadBankAccountBalanceUncleared({
      bankAccountID: this.$route.params.id,
      startDate: this.selectedTargetString,
      endDate: this.selectedTargetString
    })
    console.debug('bankAccountUnclearedBalance=' + JSON.stringify(this.bankAccountBalance))

    this.loading = false
  },

  async refresh() {
    this.loading = true

    this.formData = { ...PRESET_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    if (this.selectedTargetString == '') {
      this.targetDate = new Date(this.formatDateFn(this.$route.params.defaultTargetDate))
    }

    await this.reloadGridsWithTargetDate()

    this.finInstBankName = this.$route.params.finInstBankName

    this.loading = false
  },

  getFormPayload() {
    const today = moment()

    let checkbookEntries = []
    Array.from(this.checkedRows1).forEach(element => {
      if (element) {
        console.debug('element' + JSON.stringify(element.amount))
        console.debug(element.checkbookEntryID)

        if (element.checkbookEntryID) {
          checkbookEntries.push(element.checkbookEntryID)
        }
      }
    })

    let bankAccountListingIds = []
    Array.from(this.checkedRows2).forEach(element => {
      if (element) {
        console.debug('element' + JSON.stringify(element.amount))
        console.debug(element.bankAccountListingID)

        if (element.bankAccountListingID) {
          bankAccountListingIds.push(element.bankAccountListingID)
        }
      }
    })

    const payload = {
      clearedDate: this.formatDateFnPost(today.toDate()),
      checkbookEntryIds: checkbookEntries,
      bankAccountListingIds: bankAccountListingIds
    }
    return payload
  },

  async onFormSubmit() {
    if (this.checkedRows1.length == 0 || this.checkedRows2.length == 0) {
      notifyError(
        'Please make sure that you have made selections for both check book entries and transactions.'
      )
      return
    }

    if (this.totalSelectedChecks != this.totalSelectedTransactions) {
      notifyError(
        'Please make sure the selected check totals match the selected transaction totals.'
      )
      return
    }

    try {
      this.loading = true
      console.debug(`checked rows ${JSON.stringify(this.checkedRows1)}`)
      console.debug(`checked rows2 ${JSON.stringify(this.checkedRows2)}`)
      console.debug(this.getFormPayload())

      const path = `/CheckbookReconciliation/CheckbookEntry/ClearCheckEntryAndBankListing?hoaID=${this.hoaId}`
      const results = await kms.post(path, this.getFormPayload())

      if (results) {
        this.formComplete = true
        this.refresh()
        this.loading = false
        notifyMessage(`Your selected entries were successfully reconciled.`)
      } else {
        notifyError('There was a problem reconciling your entries.')
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError(e)
    }
    this.loading = false
  }
}
