<template>
  <PageContent :title="$t('bankReconciliation.title')">
    <div class="container">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="columns is-12 has-text-left" style="padding:10px;">
                <h4>{{ finInstBankName }}</h4>
              </div>
              <div class="columns is-12 has-text-left" style="padding:10px; white-space:nowrap;">
                <b-field horizontal label="Target Date">
                  <b-datepicker
                    placeholder="Select date..."
                    icon="calendar-today"
                    v-model="targetDate"
                    trap-focus
                    rules="required"
                    editable
                  ></b-datepicker>
                </b-field>
              </div>
              <div class="columns is-12 has-text-left" style="padding:10px;">
                <div class="column is-half">
                  <h6>Checkbook Entries</h6>
                </div>
                <div class="column is-half">
                  <h6>Checkbook Entry Status</h6>
                </div>
              </div>
              <div class="columns box is-12">
                <div class="box list-content column is-half">
                  <b-table
                    style="max-height:400px; overflow: auto;"
                    :data="checkbookEntries"
                    :loading="loading"
                    :striped="true"
                    :sticky-header="true"
                    :checked-rows.sync="checkedRows1"
                    checkable
                    :checkbox-position="checkboxPosition"
                  >
                    <template slot="empty">
                      <empty-list v-if="!loading" />
                    </template>

                    <b-table-column
                      label="Description"
                      field="description"
                      sortable
                      v-slot="props"
                      >{{ formatFn(props.row.description) }}</b-table-column
                    >
                    <b-table-column field="Amount" label="Balance" sortable v-slot="props">{{
                      formatNumberFn(props.row.amount) | currency
                    }}</b-table-column>
                    <b-table-column
                      label="Check Number"
                      field="checkNumber"
                      sortable
                      v-slot="props"
                      >{{ formatFn(props.row.checkNumber) }}</b-table-column
                    >
                    <b-table-column
                      label="Date"
                      field="modifiedDate"
                      sortable
                      centered
                      v-slot="props"
                      >{{ formatDateFn(props.row.modifiedDate) }}</b-table-column
                    >
                  </b-table>
                </div>
                <div class="box column is-half" style="height:425px; background-color: #f8f9fa;">
                  <p>{{ debitCount }} Unreconciled Debits: {{ summedDebits | currency }}</p>
                  <p>{{ creditCount }} Unreconciled Credits: {{ summedCredits | currency }}</p>
                  <p>
                    {{ checkedRows1.length }} Selected Transactions:
                    {{ totalSelectedChecks | currency }}
                  </p>
                </div>
              </div>
              <div class="is-12">
                <div
                  class="columns is-12 has-text-left"
                  style="padding:10px; padding-bottom:10px !important;"
                >
                  <div class="column is-half">
                    <h6>Bank Account Transactions</h6>
                  </div>
                  <div class="column is-half">
                    <h6>Transaction Status</h6>
                  </div>
                </div>
                <div>&nbsp;</div>
                <div class="columns box is-12">
                  <div class="box list-content column is-half">
                    <b-table
                      style="max-height:400px; overflow: auto;"
                      :data="bankAccountListings"
                      :loading="loading"
                      :striped="true"
                      :sticky-header="true"
                      :checked-rows.sync="checkedRows2"
                      checkable
                      :checkbox-position="checkboxPosition"
                    >
                      <template slot="empty">
                        <empty-list v-if="!loading" />
                      </template>

                      <b-table-column
                        label="Description"
                        field="description"
                        sortable
                        v-slot="props"
                        >{{ formatFn(props.row.description) }}</b-table-column
                      >
                      <b-table-column field="Amount" label="Balance" sortable v-slot="props">{{
                        formatNumberFn(props.row.amount) | currency
                      }}</b-table-column>
                      <b-table-column
                        label="Check Number"
                        field="checkNumber"
                        sortable
                        v-slot="props"
                        >{{ formatFn(props.row.checkNumber) }}</b-table-column
                      >
                      <b-table-column
                        label="Date"
                        field="modifiedDate"
                        sortable
                        centered
                        v-slot="props"
                        >{{ formatDateFn(props.row.modifiedDate) }}</b-table-column
                      >
                    </b-table>
                  </div>

                  <div class="box column is-half" style="height:425px; background-color: #f8f9fa;">
                    <p>
                      {{ debitTransactionCount }} Unreconciled Debits:
                      {{ summedDebitAccounts | currency }}
                    </p>
                    <p>
                      {{ creditTransactionCount }} Unreconciled Credits:
                      {{ summedCreditAccounts | currency }}
                    </p>
                    <p>
                      {{ checkedRows2.length }} Selected Transactions:
                      {{ totalSelectedTransactions | currency }}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <footer class="footer">
                  <div class="columns content has-text-left">
                    <div class="column is-half">
                      G/L Account Balance: {{ balanceAmountCheck | currency }}
                    </div>
                    <div class="column is-half">
                      Reconciling Balance: {{ reconcilingBalance | currency }}
                    </div>
                  </div>

                  <div class="columns content has-text-left">
                    <div class="column is-half">
                      Uncleared Debits: {{ summedDebits | currency }}
                    </div>
                    <div class="column is-half" v-if="isBalanceTargetDate">
                      Bank Account Balance: {{ bankAccountBalanceCheck | currency }}
                    </div>
                    <div v-else class="column is-half">
                      Bank Account Balance:
                      <span style="color:orange;"> {{ bankAccountBalanceCheck | currency }} </span>
                    </div>
                  </div>

                  <div class="columns content has-text-left">
                    <div class="column is-half">
                      Uncleared Credits: {{ summedCredits | currency }}
                    </div>
                    <div class="column is-half">Difference: {{ differenceBalance | currency }}</div>
                  </div>
                </footer>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
            <button type="submit" class="button is-primary is-rounded" :disabled="loading">
              Reconcile
            </button>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </PageContent>
</template>

<script>
//
import PageContent from '@/containers/PageContent'
//
import EmptyList from '@/components/EmptyList'
//
import { ValidationObserver, ValidationProvider } from 'vee-validate'
//
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    EmptyList,
    PageContent,
    ValidationObserver,
    ValidationProvider
  },

  computed,
  data,

  watch,

  mounted() {
    this.refresh()
  },

  methods,

  i18n: {
    messages: {
      en: { bankReconciliation: { title: 'Bank Account Reconciliation' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em 0.5em 0 0;
  }
}

.details-menu {
  a {
    color: #838383;
    display: inline-block;
    padding: 0.5em 0;

    &:hover,
    &:active {
      color: inherit;
    }
    &.router-link-active {
      color: #ff7300; // #381;
      &::after {
        content: '→';
      }
    }
  }
}
</style>
