import moment from 'moment'

export const PRESET_FORM_VALUES = {}

export function data() {
  return {
    formComplete: false,
    formData: {
      ...PRESET_FORM_VALUES
    },
    loading: true,
    finInstBankName: '',
    unreconciledCount1: 0,
    selectedCount1: 0,
    unreconciledCount2: 0,
    fields: ['selected', 'isActive', 'description', 'balance', 'checkNumber', 'modifiedDate'],
    checkboxPosition: 'left',
    checkedRows1: [],
    checkedRows2: [],

    //targetDate: null,
    targetDate: new Date(),

    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    formatDateFnPost: function(value) {
      console.log('formatDateFnPost', { value })

      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DD') : ''
    },
    formatFn: function(value) {
      return value != null ? value : ''
    },
    formatNumberFn: function(value) {
      return value != null ? value : 0
    }
  }
}
